<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								6
							</div>
							<div class="steps-number__total">
								/10
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Выбери в каком регионе, городе ты проживаешь
						</div>
					</div>
					<div class="questionary__form">
						<div class="field-questionary">
							<div class="field-questionary__title">
								Введите свой город
							</div>
							<div
								class="select"
								:class="{
									'select--error': $v.form.city.$error
								}"
							>
								<div class="select-button">
									<c-select
										:key="chosenCity"
										autosearch-city
										is-onboarding
										:region-name="form.region"
										:name="'Введите свой город'"
										:default-data="form.city"
										:is-error="$v.form.city.$dirty && !$v.form.city.required"
										@selected="selectCity"
										@defaultRegion="chooseRegion"
									/>
								</div>
							</div>
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								Введите свой регион
							</div>
							<div
								class="select"
								:class="{
									'select--error': $v.form.region.$error
								}"
							>
								<div class="select-button">
									<c-select
										:key="chosenRegion"
										:name="'Введите свой регион:'"
										autosearch-region
										is-onboarding
										:default-data="form.region"
										:is-error="$v.form.region.$dirty && !$v.form.region.required"
										@selected="selectRegion"
										@defaultCity="chooseCity"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							v-if="!isLoading"
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="'/img/icons/icon__arrow-right--white.svg'| prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
						<div
							v-else
							class="button orange button-preloader"
							style="width:132px"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';
import UserEd from '@/api/userEducation';

export default {
	data() {
		return {
            chosenCity: 0,
            chosenRegion: 0,
            isLoading: false,
			cities: [],
			dataFormStudentStepOne: JSON.parse(localStorage.getItem('dataStudentOnboarding')),
			form: {
				region: '',
				city: '',
			},
		};
	},
	computed: {
		...mapGetters('user', {
			user: 'userInfo',
			userID: 'userID'
		})
	},

	validations: {
		form: {
			region: {
				required
			},
			city: { required },
		},
		isPersonal: {
			sameAs: sameAs(() => true)
		},
		isAgree: {
			sameAs: sameAs(() => true)
		}
	},

    created() {
        this.getUserData()
    },

	methods: {
		...mapActions('user', ['change', 'detail']),
        getUserData() {
            this.detail()
                .then(user => {
                    this.form.region = user.region
                })
        },
		async next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
                this.isLoading = true
                await UserEd.addStudyPlace({
                    data: {
                        region: this.form.region,
                        city: this.form.city,
                        institution: {
                            name: this.dataFormStudentStepOne.institution.name
                        },
                        name: this.dataFormStudentStepOne.type_institution === 'university' ? 'Неоконченное высшее' : 'Неоконченное среднее специальное',
                        direction_of_study: this.dataFormStudentStepOne.direction_of_study,
                        specialization: this.dataFormStudentStepOne.specialization,
                        number_course: this.dataFormStudentStepOne.number_course,
                        type_institution: this.dataFormStudentStepOne.type_institution,
                        primary_education: true
                    },
                    id: this.userID
                });
				await this.change({
					region: this.form.region,
					city: {
						name: this.form.city
					},
					employment_type: [],
					is_onbording: true,
				}).then(() => {
                    this.isLoading = false
                    this.$emit('next','StudentStepEight')
                })
			}
		},
		selectCity(city) {
			this.form.city = city;
		},
		selectSchool(school) {
			this.form.school = school;
		},
        selectRegion(val) {
            this.form.region = val;
        },
        chooseRegion(val) {
            if ( this.form.region === '' ) {
                this.form.region = val
                this.chosenRegion++
            }
        },
        chooseCity(val) {
            if ( this.form.city !== '') {
                this.form.city = val
                this.chosenCity++
            }
        }
	}
};
</script>

<style lang="less">
.onboarding-label {
	input {
		&[placeholder] {
			&:focus {
				// opacity:0;
			}
		}
	}
}
.class--error {
	&::before {
		border-color: red;
	}
}
</style>

