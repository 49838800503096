<template>
	<div class="sections">
		<section class="intro">
			<div class="content">
				<div class="intro__grid">
					<div class="intro__content">
						<h1 class="page-title">
							<span>Отлично!</span>
						</h1>
						<div class="block-title">
							В «Лифте в будущее» тебя ждет профориентационный тест и рассказ о востребованных профессиях, курсы по soft skills.
						</div>
						<div class="intro__text">
							За каждый шаг в «Лифте» ты будешь получать награду
						</div>
						<button
							class="button orange"
							@click="goToPlatform"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
					<div
						class="intro__pic intro__pic--glow pic--sent"
					/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
    methods: {
        goToPlatform () {
            // this.$emit('next', 'Completed');
            localStorage.setItem('isOnboardingFinished', 'true')
            window.location.href = localStorage.getItem('currentPage') ? localStorage.getItem('currentPage') : '/platform'
        }
    }
}
</script>

<style>
.pic--sent {
	background-image: url(../../../assets/img/pages/onboarding/pic__onboarding-1.png);
}
</style>
