<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								4
							</div>
							<div class="steps-number__total">
								/9
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Какой у тебя карьерный статус <br>на данный момент?
						</div>
					</div>
					<div class="questionary__content">
						<div class="questionary__list">
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>

								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="6"
								>
								<div class="question__text"><span>Есть опыт</span> работы</div>
								<img
									:src="'/img/pages/onboarding/pic__question-6--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="7"
								>
								<div class="question__text"><span>Нет опыта</span> работы</div>
								<img
									:src="'/img/pages/onboarding/pic__question-7--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="8"
								>
								<div class="question__text"><span>Подрабатываю</span> сейчас</div>
								<img
									:src="'/img/pages/onboarding/pic__question-8--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
						</div>
						<div
							v-if="$v.form.currentCard.$error"
							class="questionary__list"
						>
							<div class="error-field">
								Выберите карьерный статус
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { required} from 'vuelidate/lib/validators';
import {mapMutations, mapState} from 'vuex';

export default {
	data() {
		return {
			form: {
				currentCard: ''
			}
		}
	},
	validations: {
		form: {
			currentCard: {
				required
			},
		}
	},
    computed: {
        ...mapState('tb', [
            'onboardingAnswers'
        ]),
    },
	methods: {
        ...mapMutations('tb', [
            'setOnboardingAnswers'
        ]),
		next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
                this.setOnboardingAnswers(this.form.currentCard)
				localStorage.setItem('currentAim',this.form.currentCard)
                this.$emit('next','StudentStepFive')
			}
		}
	}
}
</script>

<style>

</style>
