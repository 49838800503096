<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								4
							</div>
							<div class="steps-number__total">
								/4
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
					</div>
					<div class="offer">
						<div class="offer__title">
							Посмотрите вебинар обо всех возможностях <br>«Лифта в будущее»
						</div>
						<div class="offer__text">
							Это сэкономит время для подготовки к занятиям <br>и поможет разобраться в формировании отчетности
						</div>
						<div class="offer__video">
							<img
								:src="'/img/pages/onboarding/demonstration__video-2.jpg' | prodSrcImage"
								alt="Картинка"
								class="offer__demonstration"
								@click.stop.prevent="
									modalOpen('mBanVideo', {
										id: 'videoTeacherOnboarding',
										src: 'https://553901.selcdn.ru/lift-bf/220622/Lift%20fin%208%20(1).mp4'
									})
								"
							>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="goToPlatform"
						>
							Вперед, к занятиям!
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
    methods: {
        goToPlatform () {
            localStorage.setItem('isOnboardingFinished', 'true')
            window.location.href = localStorage.getItem('currentPage') ? localStorage.getItem('currentPage') : '/platform'
        }
    }
}
</script>
