<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								1
							</div>
							<div class="steps-number__total">
								/3
							</div>
						</div>
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Какая цель у вас на «Лифте в будущее»?
						</div>
					</div>
					<div class="questionary__content">
						<div class="questionary__list questionary__list--sm">
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="Teacher"
								>
								<div class="question__text">
									Учу профориентации. Хочу проводить занятия с учениками
								</div>
								<img
									:src="'/img/pages/onboarding/pic__question-21--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="Specialist"
								>
								<div class="question__text">
									Ищу работу.<br>Хочу учиться
								</div>
								<img
									:src="'/img/pages/onboarding/pic__question-22--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<div
								v-if="$v.form.currentCard.$error"
								class="questionary__list questionary__list--sm"
							>
								<div class="error-field">
									Выберите цель
								</div>
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { required} from 'vuelidate/lib/validators';
export default {
	data() {
		return {
			form: {
				currentCard:'',
			}
		}
	},
	validations: {
		form: {
			currentCard: {
				required
			},
		}
	},
	methods: {
		next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else if(this.form.currentCard === 'Specialist'){
				this.$emit('next','SpecialistStepTwo')
				localStorage.setItem('currentAim',this.form.currentCard);
				localStorage.setItem('userRole',this.form.currentCard);
			} else {
				this.$emit('next','TeacherStepTwo')
				localStorage.setItem('currentAim',this.form.currentCard);
                localStorage.setItem('userRole',this.form.currentCard);
			}
		}
	}
}
</script>

<style></style>
