var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sections"},[_c('section',{staticClass:"questionary"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"panel-onboarding"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"questionary__content"},[_c('div',{staticClass:"questionary__list"},[_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"9"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"9")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "9")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Решать проблемы ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-9--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"10"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"10")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "10")}}}),_c('div',{staticClass:"question__text"},[_vm._v("Общаться")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-10--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"11"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"11")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "11")}}}),_c('div',{staticClass:"question__text"},[_vm._v("Помогать людям")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-11--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"12"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"12")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "12")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Придумывать что-то ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-12--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"13"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"13")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "13")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Заботиться о природе ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-13--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"14"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"14")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "14")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Быть лидером команды ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-14--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"15"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"15")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "15")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Реализовывать проекты ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-15--x2.png'),"alt":"Иконка"}})])]),(_vm.$v.form.currentCard.$error)?_c('div',{staticClass:"questionary__list"},[_c('div',{staticClass:"error-field"},[_vm._v(" Выберите что тебе нравится ")])]):_vm._e()]),_c('div',{staticClass:"panel-onboarding__footer"},[_c('button',{staticClass:"questionary__back"}),_c('button',{staticClass:"button orange",on:{"click":_vm.next}},[_vm._v(" Вперед "),_c('img',{staticClass:"button__icon-right",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon__arrow-right--white.svg'),"alt":"Иконка"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('div',{staticClass:"steps-number"},[_c('div',{staticClass:"steps-number__current"},[_vm._v(" 7 ")]),_c('div',{staticClass:"steps-number__total"},[_vm._v(" /9 ")])]),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-onboarding__header"},[_c('div',{staticClass:"block-title"},[_vm._v(" Что тебе больше нравится? ")])])
}]

export { render, staticRenderFns }