<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								5
							</div>
							<div class="steps-number__total">
								/9
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							{{ variants[aim - 6].title }}
						</div>
					</div>
					<div class="reaction">
						<div class="reaction__content">
							<div class="reaction__title">
								{{ variants[aim - 6].informations }}
							</div>
							<div class="reaction__text">
								{{ variants[aim - 6].description }}
							</div>
						</div>
						<img
							v-if="aim === '6'"
							:src="'/img/pages/onboarding/pic__reaction-2.png' | prodSrcImage"
							alt="Картинка"
							class="reaction__pic"
						>
						<img
							v-if="aim === '7'"
							:src="'/img/pages/onboarding/pic__reaction-3.png' | prodSrcImage"
							alt="Картинка"
							class="reaction__pic"
						>
						<img
							v-if="aim === '8'"
							:src="'/img/pages/onboarding/pic__reaction-4.png' | prodSrcImage"
							alt="Картинка"
							class="reaction__pic"
						>
					</div>
					<div class="brands">
						<div class="brand">
							<div class="brand__title">
								Главный партнер
							</div>
							<img
								:src="
									'/img/brands/brand__mts--black.svg'
										| prodSrcImage
								"
								alt="МТС"
								class="brand__logo"
							>
						</div>
						<div class="brand">
							<img
								:src="
									'/img/brands/brand__segezha--black.svg'
										| prodSrcImage
								"
								alt="Segezha Group"
								class="brand__logo"
							>
						</div>
						<div class="brand">
							<img
								:src="
									'/img/brands/brand__etalon--black.svg'
										| prodSrcImage
								"
								alt="Эталон"
								class="brand__logo"
							>
						</div>
						<div class="brand">
							<img
								:src="
									'/img/brands/brand__step--black.svg'
										| prodSrcImage
								"
								alt="Степь Агрохолдинг"
								class="brand__logo"
							>
						</div>
						<div class="brand">
							<img
								:src="
									'/img/brands/brand__binnopharm--black.svg'
										| prodSrcImage
								"
								alt="Binnopharm Group"
								class="brand__logo"
							>
						</div>
						<div class="brand">
							<img
								:src="
									'/img/brands/brand__medsi--black.svg'
										| prodSrcImage
								"
								alt="Медси"
								class="brand__logo"
							>
						</div>
						<div class="brand">
							<img
								:src="
									'/img/brands/brand__besk--black.svg'
										| prodSrcImage
								"
								alt="АО БЭСК"
								class="brand__logo"
							>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	data: function() {
		return {
			aim: localStorage.getItem('currentAim'),
			variants: [
				{
					variant:1,
					imageName: 'pic__reaction-2',
					title: 'Ура, поздравляю!',
					informations:
						'Изучи возможности получения стипендии и приобретения востребованных навыков на нашей платформе. Здесь представлено много полезных материалов: онлайн-курсы, тестирования и чек-листы',
					description:
						'«Лифт в будущее» — образовательный проект одного из крупнейших работодателей страны — АФК «Система».'
				},
				{
					variant:2,
					imageName: 'pic__reaction-3',
					title: 'Все когда-то начинали с нуля!',
					informations:
						'Мы поможем тебе составить резюме и узнать свои сильные и слабые стороны, пройти полезные курсы и узнать лайфхаки на платформе «Лифт в будущее».',
					description:
						'«Лифт в будущее» — образовательный проект одного из крупнейших работодателей страны — АФК «Система».'
				},
				{
					variant:3,
					imageName: 'pic__reaction-4',
					title:
						'Супер! Ты мотивированный и целеустремленный человек, мы таких ценим!',
					informations:
						'Изучи возможности получения стипендии и приобретения востребованных навыков на нашей платформе. Здесь представлено много полезных материалов: онлайн-курсы, тестирования и чек-листы',
					description:
						'«Лифт в будущее» — образовательный проект одного из крупнейших работодателей страны — АФК «Система».'
				}
			]
		};
	},
	methods: {
		next() {
			this.$emit('next', 'StudentStepSix');
		},
	}
};
</script>

<style></style>
