var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sections"},[_c('section',{staticClass:"questionary"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"panel-onboarding panel-onboarding--glow"},[_vm._m(0),_c('div',{staticClass:"questionary__form"},[_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" В каком городе ты живешь? ")]),_c('div',{staticClass:"select",class:{
								'select--error': _vm.$v.form.city.$error
							}},[_c('div',{staticClass:"select-button"},[_c('c-select',{key:_vm.chosenCity,attrs:{"autosearch-city":"","is-onboarding":"","region-name":_vm.form.region,"name":'Введите свой город',"default-data":_vm.form.city,"is-error":_vm.$v.form.city.$dirty && !_vm.$v.form.city.required},on:{"selected":_vm.selectCity,"defaultRegion":_vm.chooseRegion}})],1)])]),_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" В каком регионе ты живешь? ")]),_c('div',{staticClass:"select",class:{
								'select--error': _vm.$v.form.region.$error
							}},[_c('div',{staticClass:"select-button"},[_c('c-select',{key:_vm.chosenRegion,attrs:{"name":'Введите свой регион:',"autosearch-region":"","is-onboarding":"","default-data":_vm.form.region,"is-error":_vm.$v.form.region.$dirty && !_vm.$v.form.region.required},on:{"selected":_vm.selectRegion,"defaultCity":_vm.chooseCity}})],1)])]),_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" В какой школе ты учишься? ")]),_c('div',{staticClass:"select",class:{
								'select--error': _vm.$v.form.school.$error
							}},[_c('div',{staticClass:"select-button"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.school.$model),expression:"$v.form.school.$model"}],staticClass:"select-value",attrs:{"type":"text","placeholder":"Введите свою школу"},domProps:{"value":(_vm.$v.form.school.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.school, "$model", $event.target.value)}}}),(_vm.$v.form.school.$error)?_c('div',{staticClass:"error-field"},[_vm._v(" ВВЕДИТЕ ШКОЛУ ")]):_vm._e()])])]),_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" В каком ты классе? ")]),_c('div',{staticClass:"select",class:{
								'select--error': _vm.$v.form.class.$error
							}},[_c('div',{staticClass:"select-button"},[_c('c-select',{attrs:{"options":_vm.classList.map(x => x.number),"is-onboarding":"","name":'Выбери свой класс',"default-data":_vm.form.class,"is-error":_vm.$v.form.class.$dirty && !_vm.$v.form.class.required},on:{"selected":_vm.selectClass}})],1)])])]),_c('div',{staticClass:"panel-onboarding__footer"},[_c('button',{staticClass:"questionary__back"}),_c('button',{staticClass:"button orange",on:{"click":_vm.next}},[_vm._v(" Вперед "),_c('img',{staticClass:"button__icon-right",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon__arrow-right--white.svg'),"alt":"Иконка"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-onboarding__header"},[_c('div',{staticClass:"block-title"},[_vm._v(" Расскажи о себе ")])])
}]

export { render, staticRenderFns }