var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sections"},[_c('section',{staticClass:"questionary"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"panel-onboarding"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"questionary__form"},[_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" Выбери тип учебного заведения ")]),_c('div',{staticClass:"radios"},[_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.typeInst.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.typeInst.$model),expression:"$v.form.typeInst.$model"}],attrs:{"type":"radio","name":"name-2","value":"university"},domProps:{"checked":_vm._q(_vm.$v.form.typeInst.$model,"university")},on:{"change":function($event){return _vm.$set(_vm.$v.form.typeInst, "$model", "university")}}}),_c('span',[_vm._v("Вуз")])]),_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.typeInst.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.typeInst.$model),expression:"$v.form.typeInst.$model"}],attrs:{"type":"radio","name":"name-2","value":"college"},domProps:{"checked":_vm._q(_vm.$v.form.typeInst.$model,"college")},on:{"change":function($event){return _vm.$set(_vm.$v.form.typeInst, "$model", "college")}}}),_c('span',[_vm._v("Колледж")])])]),(_vm.$v.form.typeInst.$error)?_c('div',{staticClass:"error-field"},[_vm._v(" Введи название учебного заведения ")]):_vm._e()]),_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" Введи название учебного заведения ")]),_c('c-select',{attrs:{"is-closed":"","name":'Введи название учебного заведения:',"autosearch-education":"","autosearch-education-onboarding":"","is-onboarding":"","default-data":_vm.form.educations.name,"is-error":_vm.$v.form.educations.$error},on:{"selected":_vm.selectEduFac}})],1),_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" Выбери направление подготовки ")]),_c('select-2',{attrs:{"is-closed":"","onboarding":"","error":_vm.$v.form.direction.$error
									? 'Выбери направление подготовки'
									: '',"options":_vm.directions,"label":_vm.form.educations === ''
									? ''
									: '',"placeholder":"Выбери из списка направленний"},on:{"selected":function($event){return _vm.selectDirection($event, 'educations')}}})],1),_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" На каком ты курсе? ")]),_c('div',{staticClass:"radios"},[_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.course.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.course.$model),expression:"$v.form.course.$model"}],attrs:{"type":"radio","name":"name-1","value":"1 курс"},domProps:{"checked":_vm._q(_vm.$v.form.course.$model,"1 курс")},on:{"change":function($event){return _vm.$set(_vm.$v.form.course, "$model", "1 курс")}}}),_c('span',[_vm._v("1 курс")])]),_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.course.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.course.$model),expression:"$v.form.course.$model"}],attrs:{"type":"radio","name":"name-1","value":"2 курс"},domProps:{"checked":_vm._q(_vm.$v.form.course.$model,"2 курс")},on:{"change":function($event){return _vm.$set(_vm.$v.form.course, "$model", "2 курс")}}}),_c('span',[_vm._v("2 курс")])]),_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.course.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.course.$model),expression:"$v.form.course.$model"}],attrs:{"type":"radio","name":"name-1","value":"3 курс"},domProps:{"checked":_vm._q(_vm.$v.form.course.$model,"3 курс")},on:{"change":function($event){return _vm.$set(_vm.$v.form.course, "$model", "3 курс")}}}),_c('span',[_vm._v("3 курс")])]),_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.course.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.course.$model),expression:"$v.form.course.$model"}],attrs:{"type":"radio","name":"name-1","value":"4 курс"},domProps:{"checked":_vm._q(_vm.$v.form.course.$model,"4 курс")},on:{"change":function($event){return _vm.$set(_vm.$v.form.course, "$model", "4 курс")}}}),_c('span',[_vm._v("4 курс")])]),_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.course.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.course.$model),expression:"$v.form.course.$model"}],attrs:{"type":"radio","name":"name-1","value":"5 - 6 курс"},domProps:{"checked":_vm._q(_vm.$v.form.course.$model,"5 - 6 курс")},on:{"change":function($event){return _vm.$set(_vm.$v.form.course, "$model", "5 - 6 курс")}}}),_c('span',[_vm._v("5 - 6 курс")])])]),(_vm.$v.form.course.$error)?_c('div',{staticClass:"error-field"},[_vm._v(" Выберите курс ")]):_vm._e()])]),_c('div',{staticClass:"panel-onboarding__footer"},[_c('button',{staticClass:"questionary__back"}),_c('button',{staticClass:"button orange",on:{"click":_vm.next}},[_vm._v(" Вперед "),_c('img',{staticClass:"button__icon-right",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon__arrow-right--white.svg'),"alt":"Иконка"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('div',{staticClass:"steps-number"},[_c('div',{staticClass:"steps-number__current"},[_vm._v(" 1 ")]),_c('div',{staticClass:"steps-number__total"},[_vm._v(" /9 ")])]),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-onboarding__header"},[_c('div',{staticClass:"block-title"},[_vm._v(" Где ты учишься? ")])])
}]

export { render, staticRenderFns }