<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								2
							</div>
							<div class="steps-number__total">
								/3
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
					</div>
					<div class="block-title block-title--center block-title--dark">
						Вы являетесь сотрудником одной из компаний Группы АФК «Система» ?
					</div>
					<div class="questionary__form">
						<div class="field-questionary">
							<div class="radios radios--center">
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.isAfkSystem.$error
									}"
								>
									<input
										v-model="$v.form.isAfkSystem.$model"
										type="radio"
										name="name-1"
										:value="true"
									>
									<span>Да</span>
								</label>
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.isAfkSystem.$error
									}"
								>
									<input
										v-model="$v.form.isAfkSystem.$model"
										type="radio"
										name="name-1"
										:value="false"
									>
									<span>Нет</span>
								</label>
							</div>
							<div
								v-if="$v.form.isAfkSystem.$error"
								class="error-field error-field--center"
							>
								Выберите ответ
							</div>
						</div>
						<div class="block-title">
							Выберите город, в котором живете
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								В каком городе вы живете?
							</div>
							<div
								class="select"
								:class="{
									'select--error': $v.form.city.$error
								}"
							>
								<div class="select-button">
									<c-select
										autosearch-city
										is-onboarding
										is-city
										:name="'Введите свой город'"
										:default-data="form.city"
										:is-error="$v.form.city.$dirty && !$v.form.city.required"
										@selected="selectCity"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';


export default {
	data() {
		return {
			cities: [],
			aim: localStorage.getItem('currentAim'),
			user_role: localStorage.getItem('userRole'),
			form: {
				city: '',
				isAfkSystem:'',
			},
		};
	},

	computed: {
		...mapGetters('user', {
			user: 'userInfo',
			userID: 'userID'
		})
	},

	validations: {
		form: {
			city: { required },
			isAfkSystem:{required}
		},
	},
	methods: {
		...mapActions('user', ['changeOnboarding']),
		async next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
				await this.changeOnboarding({
					user_role:	this.aim === 'Teacher' ? 'Teacher' : 'Specialist',
					goal: this.aim,
					is_afk_employee: this.form.isAfkSystem,
					is_onbording: true,
					city: {
						name: this.form.city
					},
					employment_type: []
				})
				this.$emit('next', 'SpecialistStepThree');
			}
		},
		selectCity(city) {
			this.form.city = city;
		},
	}
};
</script>
