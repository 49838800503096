var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sections"},[_c('section',{staticClass:"questionary"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"panel-onboarding"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"questionary__form"},[_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" Введите свой город ")]),_c('div',{staticClass:"select",class:{
								'select--error': _vm.$v.form.city.$error
							}},[_c('div',{staticClass:"select-button"},[_c('c-select',{key:_vm.chosenCity,attrs:{"autosearch-city":"","is-onboarding":"","region-name":_vm.form.region,"name":'Введите свой город',"default-data":_vm.form.city,"is-error":_vm.$v.form.city.$dirty && !_vm.$v.form.city.required},on:{"selected":_vm.selectCity,"defaultRegion":_vm.chooseRegion}})],1)])]),_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" Введите свой регион ")]),_c('div',{staticClass:"select",class:{
								'select--error': _vm.$v.form.region.$error
							}},[_c('div',{staticClass:"select-button"},[_c('c-select',{key:_vm.chosenRegion,attrs:{"name":'Введите свой регион:',"autosearch-region":"","is-onboarding":"","default-data":_vm.form.region,"is-error":_vm.$v.form.region.$dirty && !_vm.$v.form.region.required},on:{"selected":_vm.selectRegion,"defaultCity":_vm.chooseCity}})],1)])])]),_c('div',{staticClass:"panel-onboarding__footer"},[_c('button',{staticClass:"questionary__back"}),(!_vm.isLoading)?_c('button',{staticClass:"button orange",on:{"click":_vm.next}},[_vm._v(" Вперед "),_c('img',{staticClass:"button__icon-right",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon__arrow-right--white.svg'),"alt":"Иконка"}})]):_c('div',{staticClass:"button orange button-preloader",staticStyle:{"width":"132px"}},[_vm._m(2)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('div',{staticClass:"steps-number"},[_c('div',{staticClass:"steps-number__current"},[_vm._v(" 6 ")]),_c('div',{staticClass:"steps-number__total"},[_vm._v(" /10 ")])]),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"}),_c('div',{staticClass:"step"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-onboarding__header"},[_c('div',{staticClass:"block-title"},[_vm._v(" Выбери в каком регионе, городе ты проживаешь ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-preloader-icons"},[_c('div',{staticClass:"button-preloader-icon"}),_c('div',{staticClass:"button-preloader-icon"}),_c('div',{staticClass:"button-preloader-icon"})])
}]

export { render, staticRenderFns }