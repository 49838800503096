<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="offer">
						<div class="offer__title">
							Пройди входное тестирование для оценки потенциала и особенностей мышления, загрузи работу (проект или решенный кейс) или пройди тестирование на профессиональные компетенции от компаний Группы АФК «Система» и <span>ты сможешь претендовать на именную
								стипендию</span>.
						</div>
						<div class="offer__text">
							Она назначается лучшим школьникам, прошедшим конкурсный отбор. После чего они получают по 5 000 рублей ежемесячно на протяжении 5 месяцев
						</div>
						<img
							:src="
								'/img/pages/onboarding/pic__offer.png'
									| prodSrcImage
							"
							alt="Картинка"
							class="offer__pic"
						>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="goToPlatform"
						>
							Вперед на «Лифте в будущее»
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
    methods: {
        goToPlatform () {
            localStorage.setItem('isOnboardingFinished', 'true')
            window.location.href = localStorage.getItem('currentPage') ? localStorage.getItem('currentPage') : '/platform'
        }
    }
}
</script>
