<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								1
							</div>
							<div class="steps-number__total">
								/9
							</div>
						</div>
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Где ты учишься?
						</div>
					</div>
					<div class="questionary__form">
						<div class="field-questionary">
							<div class="field-questionary__title">
								Выбери тип учебного заведения
							</div>
							<div class="radios">
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.typeInst.$error
									}"
								>
									<input
										v-model="$v.form.typeInst.$model"
										type="radio"
										name="name-2"
										value="university"
									>
									<span>Вуз</span>
								</label>
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.typeInst.$error
									}"
								>
									<input
										v-model="$v.form.typeInst.$model"
										type="radio"
										name="name-2"
										value="college"
									>
									<span>Колледж</span>
								</label>
							</div>
							<div
								v-if="$v.form.typeInst.$error"
								class="error-field"
							>
								Введи название учебного заведения
							</div>
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								Введи название учебного заведения
							</div>
							<c-select
								is-closed
								:name="'Введи название учебного заведения:'"
								autosearch-education
								autosearch-education-onboarding
								is-onboarding
								:default-data="form.educations.name"
								:is-error="$v.form.educations.$error"
								@selected="selectEduFac"
							/>
							<!-- <select-2
								onboarding
								:error="
									$v.form.educations.$error
										? 'ВЫБЕРИТЕ УЧЕБНОЕ ЗАВЕДЕНИЕ'
										: ''
								"
								:options="educations"
								:label="
									form.educations === ''
										? ''
										: ''
								"
								placeholder="Выбери учебное заведение"
								@selected="
									selectEducations($event, 'educations')
								"
							/> -->
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								Выбери направление подготовки
							</div>
							<!--							<input-->
							<!--								v-model="$v.form.direction.$model"-->
							<!--								type="text"-->
							<!--								class="select-value"-->
							<!--								placeholder="Введи направление подготовки:"-->
							<!--							>-->
							<select-2
								is-closed
								onboarding
								:error="
									$v.form.direction.$error
										? 'Выбери направление подготовки'
										: ''
								"
								:options="directions"
								:label="
									form.educations === ''
										? ''
										: ''
								"
								placeholder="Выбери из списка направленний"
								@selected="
									selectDirection($event, 'educations')
								"
							/>
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								На каком ты курсе?
							</div>
							<div class="radios">
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.course.$error
									}"
								>
									<input
										v-model="$v.form.course.$model"
										type="radio"
										name="name-1"
										value="1 курс"
									>
									<span>1 курс</span>
								</label>
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.course.$error
									}"
								>
									<input
										v-model="$v.form.course.$model"
										type="radio"
										name="name-1"
										value="2 курс"
									>
									<span>2 курс</span>
								</label>
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.course.$error
									}"
								>
									<input
										v-model="$v.form.course.$model"
										type="radio"
										name="name-1"
										value="3 курс"
									>
									<span>3 курс</span>
								</label>
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.course.$error
									}"
								>
									<input
										v-model="$v.form.course.$model"
										type="radio"
										name="name-1"
										value="4 курс"
									>
									<span>4 курс</span>
								</label>
								<label
									class="radio"
									:class="{
										'radio--error': $v.form.course.$error
									}"
								>
									<input
										v-model="$v.form.course.$model"
										type="radio"
										name="name-1"
										value="5 - 6 курс"
									>
									<span>5 - 6 курс</span>
								</label>
							</div>
							<div
								v-if="$v.form.course.$error"
								class="error-field"
							>
								Выберите курс
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			isClosed: false,
			directions: ['Промышленность', 'IT', 'Экономика и финансы', 'Медицина и фармацевтика', 'Медиа и шоу бизнес', 'Наука и образование', 'Креативные индустрии', 'Строительство и девелопмент', 'Юриспруденция', 'Сфера услуг', 'Маркетинг, PR, digital реклама'],
			educations: [],
			form: {
				typeInst: '',
				educations: '',
				course: '',
				direction: ''
			},
			classList: [
				{
					number: 8
				},
				{
					number: 9
				},
				{
					number: 10
				},
				{
					number: 11
				}
			]
		};
	},
	computed: {
		...mapGetters('user', {
			userID: 'userID'
		})
	},

	validations: {
		form: {
			course: {
				required
			},
			typeInst: {
				required
			},
			educations: {
				required
			},
			direction: {
				required
			}
		}
	},
    created() {
        this.getAllEducations({ search: '', page: 1, page_size: 2000 }).then(
            res => {
                this.educations = res.map(el => {
                    return el.name;
                });
            }
        );
        // this.getAllSections().then(res => {
        //     this.directions = res.map(el => {
        //         return el.name;
        //     });
        // });
    },
	methods: {
		...mapActions('cmn', ['getAllEducations', 'getGlobalSchools']),
		...mapActions('tb', [
			'getAllCourses',
			'getAllSections',
			'getAllTopics',
			'startCourse',
			'getAllTypeCourses'
		]),
		async next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
				let data = {
					number_course: this.form.course,
					type_institution: this.form.typeInst,
					institution: {
						name: this.form.educations
					},
                    specialization: this.form.direction
				};
				localStorage.setItem(
					'dataStudentOnboarding',
					JSON.stringify(data)
				);
				this.$emit('next', 'StudentStepTwo');
			}
		},
		selectDirection(direction) {
			this.form.direction = direction;
		},
		// selectEducations(educations) {
		// 	this.form.educations = educations;
		// },
		selectEduFac(educations) {
			this.form.educations = educations;
		}
	}
};
</script>

<style></style>
