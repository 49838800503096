<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								7
							</div>
							<div class="steps-number__total">
								/9
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Что тебе больше нравится?
						</div>
					</div>
					<div class="questionary__content">
						<div class="questionary__list">
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="9"
								>
								<div class="question__text">
									Решать проблемы
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-9--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="10"
								>
								<div class="question__text">Общаться</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-10--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="11"
								>
								<div class="question__text">Помогать людям</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-11--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="12"
								>
								<div class="question__text">
									Придумывать что-то
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-12--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="13"
								>
								<div class="question__text">
									Заботиться о природе
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-13--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="14"
								>
								<div class="question__text">
									Быть лидером команды
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-14--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="15"
								>
								<div class="question__text">
									Реализовывать проекты
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-15--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
						</div>
						<div
							v-if="$v.form.currentCard.$error"
							class="questionary__list"
						>
							<div class="error-field">
								Выберите что тебе нравится
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {mapMutations, mapState} from 'vuex';

export default {
	data() {
		return {
			form: {
				currentCard: ''
			}
		};
	},
	validations: {
		form: {
			currentCard: {
				required
			}
		}
	},
    computed: {
        ...mapState('tb', [
            'onboardingAnswers'
        ]),
    },
	methods: {
        ...mapMutations('tb', [
            'setOnboardingAnswers'
        ]),
		next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
                this.setOnboardingAnswers(this.form.currentCard)
				this.$emit('next', 'StudentStepNine');
				this.$emit('currentAim', this.form.currentCard)
			}
		}
	}
};
</script>

<style></style>
