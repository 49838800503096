<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								2
							</div>
							<div class="steps-number__total">
								/9
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Ты уже задумываешься о будущей карьере?
						</div>
						<div class="panel-onboarding__subtitle">
							Какую цель перед собой ставишь?
						</div>
					</div>
					<div class="questionary__content">
						<div class="questionary__list">
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="1"
								>
								<div class="question__text">Определиться, <span>куда пойти работать</span> после завершения учебы</div>
								<img
									:src="'/img/pages/onboarding/pic__question-1--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="2"
								>
								<div class="question__text">Активно <span>ищу работу!</span> Полная/Частичная занятость</div>
								<img
									:src="'/img/pages/onboarding/pic__question-2--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="3"
								>
								<div class="question__text"><span>Пока не ищу работу</span>, изучаю возможности для саморазвития</div>
								<img
									:src="'/img/pages/onboarding/pic__question-3--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="4"
								>
								<div class="question__text">Хочу <span>работать на себя</span>, планирую создать стартап</div>
								<img
									:src="'/img/pages/onboarding/pic__question-4--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{'question--error':$v.form.currentCard.$error}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="5"
								>
								<div class="question__text">Другое</div>
								<img
									:src="'/img/pages/onboarding/pic__question-5--x2.png' | prodSrcImage"
									alt="Иконка"
									class="question__pic"
								>
							</label>
						</div>
						<div
							v-if="$v.form.currentCard.$error"
							class="questionary__list"
						>
							<div class="error-field">
								Выберите цель
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { required} from 'vuelidate/lib/validators';
import {mapMutations, mapState} from 'vuex';
export default {
	data() {
		return {
			form: {
				currentCard:'',
			}
		}
	},
	validations: {
		form: {
			currentCard: {
				required
			},
		}
	},
    computed: {
        ...mapState('tb', [
            'onboardingAnswers'
        ]),
    },
	methods: {
        ...mapMutations('tb', [
            'setOnboardingAnswers'
        ]),
		next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
                this.setOnboardingAnswers(this.form.currentCard)
				localStorage.setItem('currentAim',this.form.currentCard)
                this.$emit('next','StudentStepThree')
			}
		}
	}
}
</script>
