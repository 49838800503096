<template>
	<div class="sections">
		<section class="intro">
			<div class="content">
				<div class="intro__grid">
					<div class="intro__content">
						<h1 class="page-title">
							Добро пожаловать на «Лифт в будущее»!
						</h1>
						<div
							v-if="role !== 'Specialist'"
							class="block-title"
						>
							Прежде чем мы отправимся в путь, послушай советы
							Енота и ответь на несколько вопросов
						</div>
						<a
							href="#"
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</a>
					</div>
					<div
						class="intro__pic pic-start"
					/>
				</div>
				<div class="brands">
					<div class="brand">
						<div class="brand__title">
							Главный партнер
						</div>
						<img
							:src="'/img/brands/brand__mts.svg' | prodSrcImage"
							alt="МТС"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__segezha--black.svg' | prodSrcImage"
							alt="Segezha Group"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__etalon--black.svg' | prodSrcImage"
							alt="Эталон"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__step--black.svg' | prodSrcImage"
							alt="Степь Агрохолдинг"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__binnopharm--black.svg' | prodSrcImage"
							alt="Binnopharm Group"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__medsi--black.svg' | prodSrcImage"
							alt="Медси"
							class="brand__logo"
						>
					</div>
					<div class="brand">
						<img
							:src="'/img/brands/brand__besk--black.svg' | prodSrcImage"
							alt="АО БЭСК"
							class="brand__logo"
						>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	data() {
		return {
			role: localStorage.getItem('userRole'),
		}
	},
	computed: {
		nameNextComponent() {
			return this.role === 'Schoolboy' ? 'FoormSchool' : this.role === 'Student' ? 'StudentStepOne' : 'SpecialistStepOne'
		}
	},
    methods: {
		next() {
			this.$emit('next',this.nameNextComponent)
		}
	}
};
</script>

<style lang="less">
	@import "../../assets/styles/pages/onboarding";
	.pic-start {
		background-image:url("../../assets/img/pages/onboarding/pic__onboarding-1.png");
	}
</style>
