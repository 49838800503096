<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								2
							</div>
							<div class="steps-number__total">
								/4
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Выберите <span>регион, город, школу,</span> в
							которой вы работаете
						</div>
					</div>
					<div class="questionary__form">
						<div class="field-questionary">
							<div class="field-questionary__title">
								Введите свой город
								<span>(населенный пункт)</span>
							</div>
							<div class="select">
								<div class="select-button">
									<c-select
										:key="chosenCity"
										autosearch-city
										is-onboarding
										:region-name="form.region"
										:name="'Введите свой город'"
										:default-data="form.city"
										:is-error="$v.form.city.$dirty && !$v.form.city.required"
										@selected="selectCity"
										@defaultRegion="chooseRegion"
									/>
								</div>
							</div>
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								Введите регион
							</div>
							<div class="select">
								<div class="select-button">
									<c-select
										:key="chosenRegion"
										:name="'Введите свой регион:'"
										autosearch-region
										is-onboarding
										:default-data="form.region"
										:is-error="$v.form.region.$dirty && !$v.form.region.required"
										@selected="selectRegion"
										@defaultCity="chooseCity"
									/>
								</div>
							</div>
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								Укажите школу
							</div>
							<div class="select">
								<div class="select-button">
									<input
										v-model="$v.form.school.$model"
										type="text"
										class="select-value"
										placeholder="Введите свою школу"
									>
									<div
										v-if="$v.form.school.$error"
										class="error-field"
									>
										ВВЕДИТЕ ШКОЛУ
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
            chosenCity: 0,
            chosenRegion:0,
			aim: localStorage.getItem('currentAim'),
			user_role: localStorage.getItem('userRole'),
			form: {
				region: '',
				city: '',
				school:''
			}
		};
	},

	computed: {
		...mapGetters('user', {
			user: 'userInfo',
			userID: 'userID'
		})
	},

	validations: {
		form: {
			city: { required },
			region: { required },
			school: { required }
		}
	},
    created() {
        this.getUserData()
    },
    methods: {
		...mapActions('user', ['changeOnboarding', 'detail']),
        getUserData() {
            this.detail()
                .then(user => {
                    this.form.region = user.region
                })
        },
		async next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
				await this.changeOnboarding({
					user_role: this.aim,
					goal: this.aim,
					is_onbording: true,
					city: {
						name: this.form.city
					},
					school: this.form.school,
					region: this.form.region,
					employment_type: []
				});
				this.$emit('next', 'TeacherStepThree');
			}
		},
		selectCity(city) {
			this.form.city = city;
		},
        selectRegion(val) {
            this.form.region = val;
        },
        chooseRegion(val) {
            if ( this.form.region === '' ) {
                this.form.region = val
                this.chosenRegion++
            }
        },
        chooseCity(val) {
            if ( this.form.city !== '') {
                this.form.city = val
                this.chosenCity++
            }
        }
	}
};
</script>

<style></style>
