<template>
	<div class="sections">
		<section class="achievement">
			<div class="content">
				<div class="achievement__grid">
					<div class="achievement__content">
						<h1 class="page-title">
							<span>Супер!</span> <br>
							Спасибо, теперь <br>
							мы хорошо знакомы.
						</h1>
						<div class="block-title">
							За каждый шаг в «Лифте» тебя ждет поощрение.
						</div>
						<button
							class="button orange"
							@click="goToPlatform"
						>
							Вперед на «Лифте в будущее»
						</button>
					</div>
					<div class="achievement__right">
						<div class="achievement__item">
							<img
								:src="
									'/img/pages/onboarding/pic__achievement-1.png'
										| prodSrcImage
								"
								alt="Картинка"
								class="achievement__pic"
							>
							<div class="achievement__title">
								Лови свою <br>первую ачивку
							</div>
						</div>
						<a
							href="https://t.me/addstickers/Liftbf"
							target="_blank"
							class="achievement__link"
						>Стикерпак для <br>телеграма</a>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
    methods: {
        goToPlatform () {
            localStorage.setItem('isOnboardingFinished', 'true')
            window.location.href = localStorage.getItem('currentPage') ? localStorage.getItem('currentPage') : '/platform'
        }
    }
}
</script>
