var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sections"},[_c('section',{staticClass:"questionary"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"panel-onboarding"},[_vm._m(0),_c('div',{staticClass:"offer"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"offer__video"},[_c('img',{staticClass:"offer__demonstration",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/demonstration__video-2.jpg'),"alt":"Картинка"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.modalOpen('mBanVideo', {
									id: 'videoTeacherOnboarding',
									src: 'https://553901.selcdn.ru/lift-bf/220622/Lift%20fin%208%20(1).mp4'
								})}}})])]),_c('div',{staticClass:"panel-onboarding__footer"},[_c('button',{staticClass:"questionary__back"}),_c('button',{staticClass:"button orange",on:{"click":_vm.goToPlatform}},[_vm._v(" Вперед, к занятиям! "),_c('img',{staticClass:"button__icon-right",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon__arrow-right--white.svg'),"alt":"Иконка"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('div',{staticClass:"steps-number"},[_c('div',{staticClass:"steps-number__current"},[_vm._v(" 4 ")]),_c('div',{staticClass:"steps-number__total"},[_vm._v(" /4 ")])]),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offer__title"},[_vm._v(" Посмотрите вебинар обо всех возможностях "),_c('br'),_vm._v("«Лифта в будущее» ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offer__text"},[_vm._v(" Это сэкономит время для подготовки к занятиям "),_c('br'),_vm._v("и поможет разобраться в формировании отчетности ")])
}]

export { render, staticRenderFns }