<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								3
							</div>
							<div class="steps-number__total">
								/9
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							{{
								2 === 2
									? "Отлично!"
									: "Ого! У тебя интересный подход к делу"
							}}
						</div>
					</div>
					<div class="reaction">
						<div class="reaction__content">
							<div class="reaction__title">
								В «Лифте в будущее» тебя ждет:
							</div>
							<div class="reaction__points">
								<div
									v-for="point in results[Number(aim) - 1].list"
									:key="point.id"
									class="reaction__point"
								>
									{{ point.text }}
								</div>
							</div>
						</div>
						<img
							:src="
								'/img/pages/onboarding/pic__reaction-1.png'
									| prodSrcImage
							"
							alt="Картинка"
							class="reaction__pic"
						>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {

	data() {
		return {
			aim: localStorage.getItem('currentAim'),
			results: [
				{
					name: 1,
					list: [
						{
							id: 1,
							text:
								'Профориентационный тест и рассказ о востребованных отраслях и профессиях'
						},
						{
							id: 2,
							text: 'Курсы по soft skills'
						},
						{
							id: 3,
							text: 'Консультации профессионального HR'
						}
					]
				},
				{
					name: 3,
					list: [
						{
							id: 1,
							text: 'Стажировки и вакансии'
						},
						{
							id: 2,
							text:
								'Помощь в составлении резюме и подготовке к собеседованию'
						},
						{
							id: 3,
							text:
								'Наши рекомендации тебя как специалиста в перспективные компании'
						}
					]
				},
				{
					name: 2,
					list: [
						{
							id: 1,
							text:
								'Стажировки и вакансии'
						},
						{
							id: 2,
							text: 'Помощь в составлении резюме и подготовке к собеседованию'
						},
						{
							id: 3,
							text:
								'Наши рекомендации тебя как специалиста в перспективные компании'
						}
					]
				},
				{
					name: 4,
					list: [
						{
							id: 1,
							text: 'Обучение прикладным навыкам'
						},
						{
							id: 2,
							text: 'Курсы по soft skills'
						},
						{
							id: 3,
							text: 'Возможность решать кейсы крупных компаний'
						},
						{
							id: 4,
							text:
								'Возможность найти инвестора для своего стартапа'
						}
					]
				},
				{
					name: 5,
					list: [
						{
							id: 1,
							text: 'Курсы'
						},
						{
							id: 2,
							text: 'Интенсивы'
						},
						{
							id: 3,
							text: 'Кейс-чемпионаты и практика'
						},
						{
							id: 4,
							text: 'HR-консультации'
						},
						{
							id: 5,
							text: 'Система роста внутри «Лифта в будущее»'
						}
					]
				}
			],
		};
	},
	computed:{
	},
	methods: {
		next() {
			this.$emit('next', 'StudentStepFour');
		}
	}
};
</script>

<style></style>
