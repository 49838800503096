<template>
	<div class="page-onboarding">
		<component
			:is="nameShowComponent"
			:aim="aim"
			:data-form-student-step-one="dataFormStudentStepOne"
			@next="next"
			@currentAim="currentAim"
			@getDataForm="getDataForm"
		/>
	</div>
</template>

<script>
import StartPage from './start.vue';
import FoormSchool from './school/form';
import Sent from './school/sent';
import Completed from './school/completed';
import StudentStepOne from './students/stepOne';
import StudentStepTwo from './students/stepTwo';
import StudentStepThree from './students/stepThree';
import StudentStepFour from './students/stepFour';
import StudentStepFive from './students/stepFive';
import StudentStepSix from './students/stepSix';
import StudentStepSeven from './students/stepSeven';
import StudentStepEight from './students/stepEight';
import StudentStepNine from './students/stepNine';
import StudentStepTen from './students/stepTen';
import StudentStepsCompleted from './students/completed';

import SpecialistStepOne from './specialist/stepOne';
import SpecialistStepTwo from './specialist/stepTwo';
import SpecialistStepThree from './specialist/stepThree';

import TeacherStepTwo from './teacher/stepTwo';
import TeacherStepThree from './teacher/stepThree';
import TeacherStepFour from './teacher/stepFour';

import {mapActions, mapGetters, mapState} from 'vuex';
export default {
	components: {
		StartPage,
		FoormSchool,
		Sent,
        Completed,
		StudentStepOne,
		StudentStepTwo,
		StudentStepThree,
		StudentStepFour,
		StudentStepFive,
		StudentStepSix,
		StudentStepSeven,
		StudentStepEight,
		StudentStepNine,
		StudentStepTen,
		StudentStepsCompleted,
		SpecialistStepOne,
		SpecialistStepTwo,
		SpecialistStepThree,
		TeacherStepTwo,
		TeacherStepThree,
		TeacherStepFour,
	},
	data: function() {
		return {
			activePage: '',
			school: true,
			students: true,
			nameShowComponent: 'StartPage',
			aim: '',
			dataFormStudentStepOne: null
		};
	},
	computed: {
		...mapGetters('user', {
			user: 'userInfo'
		}),
		...mapState('user', {
			role: 'user_role'
		})
	},
	watch: {
		nameShowComponent() {
			this.$scrollTo(document.querySelector('.page-onboarding'));
		}
	},
	mounted() {
		if (this.user && this.user.is_onbording) {
			this.$router.push('/');
		} else {
			let activeName = localStorage.getItem('nameComponentOnboarding');
			if (activeName) {
				this.nameShowComponent = activeName;
			}
			document.querySelector('.body').classList.add('page--onboarding');
			document
				.querySelector('.footer-role')
				.classList.add('footer--onboarding');
		}
	},
	created() {
		this.detail();
	},
	destroyed() {
		document.querySelector('.body').classList.remove('page--onboarding');
		localStorage.removeItem('nameComponentOnboarding');
	},
	methods: {
		...mapActions('user', ['detail']),
		next(name) {
			localStorage.setItem('nameComponentOnboarding', name);
			let prevName = localStorage.getItem('nameComponentOnboarding');
			this.activePage = prevName;
			if (
				this.nameShowComponent === 'StartPage' &&
				this.role === 'Schoolboy'
			) {
				this.nameShowComponent = 'FoormSchool';
			} else if (
				this.nameShowComponent === 'StartPage' &&
				this.role === 'Student'
			) {
				this.nameShowComponent = 'StudentStepOne';
			} else if (
				this.nameShowComponent === 'StartPage' &&
				this.role === 'Specialist'
			) {
				this.nameShowComponent = 'SpecialistStepOne';
			} else {
				this.nameShowComponent = name;
			}
		},
		currentAim(aim) {
			this.aim = aim;
		},
		getDataForm(data) {
			this.dataFormStudentStepOne = data;
		}
	}
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/pages/onboarding";
</style>
