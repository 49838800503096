<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding panel-onboarding--glow">
					<div class="panel-onboarding__header">
						<div class="block-title">
							Расскажи о себе
						</div>
					</div>
					<div class="questionary__form">
						<div class="field-questionary">
							<div class="field-questionary__title">
								В каком городе ты живешь?
							</div>
							<div
								class="select"
								:class="{
									'select--error': $v.form.city.$error
								}"
							>
								<div class="select-button">
									<c-select
										:key="chosenCity"
										autosearch-city
										is-onboarding
										:region-name="form.region"
										:name="'Введите свой город'"
										:default-data="form.city"
										:is-error="$v.form.city.$dirty && !$v.form.city.required"
										@selected="selectCity"
										@defaultRegion="chooseRegion"
									/>
								</div>
							</div>
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								В каком регионе ты живешь?
							</div>
							<div
								class="select"
								:class="{
									'select--error': $v.form.region.$error
								}"
							>
								<div class="select-button">
									<c-select
										:key="chosenRegion"
										:name="'Введите свой регион:'"
										autosearch-region
										is-onboarding
										:default-data="form.region"
										:is-error="$v.form.region.$dirty && !$v.form.region.required"
										@selected="selectRegion"
										@defaultCity="chooseCity"
									/>
								</div>
							</div>
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								В какой школе ты учишься?
							</div>
							<div
								class="select"
								:class="{
									'select--error': $v.form.school.$error
								}"
							>
								<div class="select-button">
									<input
										v-model="$v.form.school.$model"
										type="text"
										class="select-value"
										placeholder="Введите свою школу"
									>
									<div
										v-if="$v.form.school.$error"
										class="error-field"
									>
										ВВЕДИТЕ ШКОЛУ
									</div>
								</div>
							</div>
						</div>
						<div class="field-questionary">
							<div class="field-questionary__title">
								В каком ты классе?
							</div>
							<div
								class="select"
								:class="{
									'select--error': $v.form.class.$error
								}"
							>
								<div class="select-button">
									<c-select
										:options="classList.map(x => x.number)"
										is-onboarding
										:name="'Выбери свой класс'"
										:default-data="form.class"
										:is-error="$v.form.class.$dirty && !$v.form.class.required"
										@selected="selectClass"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { required, sameAs } from 'vuelidate/lib/validators';

import UserEd from '@/api/userEducation';

export default {
	data() {
		return {
            chosenCity: 0,
            chosenRegion: 0,
			cities: [],
			schools: [],
			labelSchool: 'Выберите школу в которой учитесь',
			studyPlace: {},
			form: {
				region: '',
				city: '',
				school: '',
				class: '1'
			},
			classList: [
				{
					number: '1'
				},
				{
					number: '2'
				},
				{
					number: '3'
				},
				{
					number: '4'
				},
				{
					number: '5'
				},
				{
					number: '6'
				},
				{
					number: '7'
				},
				{
					number: '8'
				},
				{
					number: '9'
				},
				{
					number: '10'
				},
				{
					number: '11'
				}
			]
		};
	},

	computed: {
		...mapGetters('user', {
			user: 'userInfo',
			userID: 'userID'
		})
	},

	validations: {
		form: {
			region: {
				required
			},
			school: { required },
			city: { required },
			class: {
				required
			}
		},
		isPersonal: {
			sameAs: sameAs(() => true)
		},
		isAgree: {
			sameAs: sameAs(() => true)
		}
	},
	created() {
		this.getStudyPlace(res => {
			this.studyPlace = res[0];
		});
        this.getUserData()
	},
	methods: {
		...mapActions('user', ['getStudyPlace','change', 'detail']),
        getUserData() {
            this.detail()
                .then(user => {
                    this.form.region = user.region
                })
        },
		async next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
				await UserEd.addStudyPlace({
					data: {
						region: this.form.region,
						city: this.form.city,
						school: this.form.school,
						school_class_number: this.form.class,
						name:'Неоконченное среднее',
						institution: {
							name: this.form.school
						},
                        primary_education: true
					},
					id: this.userID
				});
				await this.change({
					region: this.form.region,
					city: {
						name: this.form.city
					},
					employment_type: [],
					is_onbording: true,
				})
				this.$emit('next', 'Sent');
			}
		},
		selectCity(city) {
			this.form.city = city;
		},
        selectClass(school) {
			this.form.class = school;
		},
		selectSchool(school) {
			this.form.school = school;
		},
        selectRegion(val) {
            this.form.region = val;
        },
        chooseRegion(val) {
            if ( this.form.region === '' ) {
                this.form.region = val
                this.chosenRegion++
            }
        },
        chooseCity(val) {
            if ( this.form.city !== '') {
                this.form.city = val
                this.chosenCity++
            }
        }
	}
};
</script>

