var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sections"},[_c('section',{staticClass:"questionary"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"panel-onboarding"},[_vm._m(0),_c('div',{staticClass:"block-title block-title--center block-title--dark"},[_vm._v(" Вы являетесь сотрудником одной из компаний Группы АФК «Система» ? ")]),_c('div',{staticClass:"questionary__form"},[_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"radios radios--center"},[_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.isAfkSystem.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.isAfkSystem.$model),expression:"$v.form.isAfkSystem.$model"}],attrs:{"type":"radio","name":"name-1"},domProps:{"value":true,"checked":_vm._q(_vm.$v.form.isAfkSystem.$model,true)},on:{"change":function($event){return _vm.$set(_vm.$v.form.isAfkSystem, "$model", true)}}}),_c('span',[_vm._v("Да")])]),_c('label',{staticClass:"radio",class:{
									'radio--error': _vm.$v.form.isAfkSystem.$error
								}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.isAfkSystem.$model),expression:"$v.form.isAfkSystem.$model"}],attrs:{"type":"radio","name":"name-1"},domProps:{"value":false,"checked":_vm._q(_vm.$v.form.isAfkSystem.$model,false)},on:{"change":function($event){return _vm.$set(_vm.$v.form.isAfkSystem, "$model", false)}}}),_c('span',[_vm._v("Нет")])])]),(_vm.$v.form.isAfkSystem.$error)?_c('div',{staticClass:"error-field error-field--center"},[_vm._v(" Выберите ответ ")]):_vm._e()]),_c('div',{staticClass:"block-title"},[_vm._v(" Выберите город, в котором живете ")]),_c('div',{staticClass:"field-questionary"},[_c('div',{staticClass:"field-questionary__title"},[_vm._v(" В каком городе вы живете? ")]),_c('div',{staticClass:"select",class:{
								'select--error': _vm.$v.form.city.$error
							}},[_c('div',{staticClass:"select-button"},[_c('c-select',{attrs:{"autosearch-city":"","is-onboarding":"","is-city":"","name":'Введите свой город',"default-data":_vm.form.city,"is-error":_vm.$v.form.city.$dirty && !_vm.$v.form.city.required},on:{"selected":_vm.selectCity}})],1)])])]),_c('div',{staticClass:"panel-onboarding__footer"},[_c('button',{staticClass:"questionary__back"}),_c('button',{staticClass:"button orange",on:{"click":_vm.next}},[_vm._v(" Вперед "),_c('img',{staticClass:"button__icon-right",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon__arrow-right--white.svg'),"alt":"Иконка"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('div',{staticClass:"steps-number"},[_c('div',{staticClass:"steps-number__current"},[_vm._v(" 2 ")]),_c('div',{staticClass:"steps-number__total"},[_vm._v(" /3 ")])]),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step"})])
}]

export { render, staticRenderFns }