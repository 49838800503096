<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								8
							</div>
							<div class="steps-number__total">
								/9
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Выбери направление обучения
						</div>
					</div>
					<div class="questionary__content">
						<div class="questionary__list">
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="16"
								>
								<div class="question__text">
									Хочу подтянуть знание компьютерных программ
									(Excel, Word, Outlook, PPT)
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-16--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="17"
								>
								<div class="question__text">
									Хочу повысить личную эффективность (финансы,
									креативность)
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-17--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="18"
								>
								<div class="question__text">
									Хочу стать мастером общения
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-18--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="19"
								>
								<div class="question__text">
									Хочу подготовиться к новой работе
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-19--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
							<label
								class="question"
								:class="{
									'question--error':
										$v.form.currentCard.$error
								}"
							>
								<input
									v-model="$v.form.currentCard.$model"
									type="radio"
									name="name-1"
									class="question__value"
									value="20"
								>
								<div class="question__text">
									Хочу что-то новое и крутое
								</div>
								<img
									:src="
										'/img/pages/onboarding/pic__question-20--x2.png'
											| prodSrcImage
									"
									alt="Иконка"
									class="question__pic"
								>
							</label>
						</div>
						<div
							v-if="$v.form.currentCard.$error"
							class="questionary__list"
						>
							<div class="error-field">
								Выберите что тебе нравится
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							v-if="!isSending"
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
						<div
							v-else
							class="button orange button-preloader"
							style="width:132px"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {mapMutations, mapState} from 'vuex';
import Tb from '@/api/teachbase';

export default {
	data() {
		return {
			form: {
				currentCard: null,
			},
            isSending: false
		};
	},
	validations: {
		form: {
			currentCard: {
				required
			}
		}
	},
    computed: {
        ...mapState('tb', [
            'onboardingAnswers'
        ]),
    },
	methods: {
        ...mapMutations('tb', [
            'setOnboardingAnswers'
        ]),
		next() {
			this.$v.form.$touch();
			if (this.$v.form.$invalid) {
				this.$scrollTo(document.querySelector('.page-onboarding'));
			} else {
                this.isSending = true
                this.setOnboardingAnswers(this.form.currentCard)
                Tb.sendStudentOnboardingAnswers({outs_id: this.onboardingAnswers}).then(() => {
                    this.$emit('next', 'StudentStepTen')
                    this.isSending = false
                    this.localStorage.removeItem('onboardingAnswers')
                })
			}
		}
	}
};
</script>
