<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								3
							</div>
							<div class="steps-number__total">
								/4
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
					</div>
					<div class="panel-onboarding__header">
						<div class="block-title">
							Отлично!
						</div>
					</div>
					<div class="reaction">
						<div class="reaction__content">
							<div class="reaction__title">
								В «Лифте в будущее» вас ждут:
							</div>
							<div class="reaction__points">
								<div class="reaction__point">
									Готовые методические материалы к урокам
								</div>
								<div class="reaction__point">
									Квизы по профессиям
								</div>
								<div class="reaction__point">
									Курсы по коммуникации, обратной связи,
									личной эффективности и прикладным навыкам
								</div>
								<div class="reaction__point">
									Возможность реализации целевых показателей
									по национальному проекту «Образование»
								</div>
							</div>
						</div>
						<div class="reaction__pic-wrap">
							<img
								:src="'/img/pages/onboarding/pic__reaction-5.png' | prodSrcImage"
								alt="Картинка"
								class="reaction__pic"
							>
							<div class="reaction__notice">
								За каждое третье проведенное занятие вы будете
								получать сертификат «Профориентатор»
							</div>
						</div>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="'/img/icons/icon__arrow-right--white.svg' | prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	methods: {
		next() {
			this.$emit('next','TeacherStepFour')
		}
	}
}
</script>
<style></style>
