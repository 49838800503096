<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								7
							</div>
							<div class="steps-number__total">
								/10
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step" />
						<div class="step" />
						<div class="step" />
					</div>
					<div class="offer">
						<div class="offer__title">
							Пройди входное тестирование для оценки потенциала и особенностей мышления, загрузи работу (проект или решенный кейс) или пройди тестирование на профессиональные компетенции от компаний Группы АФК «Система» и <span>ты сможешь претендовать на именную
								стипендию</span>.
						</div>
						<div class="offer__text">
							Она назначается лучшим студентам колледжей, прошедшим конкурсный отбор. После чего они получают по 7 500 рублей ежемесячно на протяжении 5 месяцев.
							<br>
							Она назначается лучшим студентам вузов, прошедшим конкурсный отбор. После чего они получают по 20 000 рублей ежемесячно на протяжении 5 месяцев.
						</div>
						<img
							:src="
								'/img/pages/onboarding/pic__offer.png'
									| prodSrcImage
							"
							alt="Картинка"
							class="offer__pic"
						>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="
									'/img/icons/icon__arrow-right--white.svg'
										| prodSrcImage
								"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	methods: {
		next() {
			this.$emit('next', 'StudentStepEight');
		}
	}
};
</script>

<style></style>
