<template>
	<div class="sections">
		<section class="intro">
			<div class="content">
				<div class="intro__grid">
					<div class="intro__content">
						<h1 class="page-title">
							<span>Отлично!</span>
						</h1>
						<div class="block-title">
							В «Лифте в будущее» вас ждут лучшие в России курсы
							по устойчивому развитию и сверхкомпетенциям:
							системному мышлению, предвидению и управлению
							изменениями, этике и ценностям
						</div>
						<button
							class="button orange"
							@click="goToPlatform"
						>
							Вперед на Лифте в будущее
						</button>
					</div>
					<div
						class="intro__pic intro__pic--glow"
						:style="'/img/pages/onboarding/pic__onboarding-1.png' | bgrImageCover"
					/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
    methods: {
        goToPlatform () {
            localStorage.setItem('isOnboardingFinished', 'true')
            window.location.href = localStorage.getItem('currentPage') ? localStorage.getItem('currentPage') : '/platform'
        }
    }
}
</script>
