var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sections"},[_c('section',{staticClass:"questionary"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"panel-onboarding"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"questionary__content"},[_c('div',{staticClass:"questionary__list"},[_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"16"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"16")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "16")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Хочу подтянуть знание компьютерных программ (Excel, Word, Outlook, PPT) ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-16--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"17"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"17")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "17")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Хочу повысить личную эффективность (финансы, креативность) ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-17--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"18"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"18")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "18")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Хочу стать мастером общения ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-18--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"19"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"19")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "19")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Хочу подготовиться к новой работе ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-19--x2.png'),"alt":"Иконка"}})]),_c('label',{staticClass:"question",class:{
								'question--error':
									_vm.$v.form.currentCard.$error
							}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.currentCard.$model),expression:"$v.form.currentCard.$model"}],staticClass:"question__value",attrs:{"type":"radio","name":"name-1","value":"20"},domProps:{"checked":_vm._q(_vm.$v.form.currentCard.$model,"20")},on:{"change":function($event){return _vm.$set(_vm.$v.form.currentCard, "$model", "20")}}}),_c('div',{staticClass:"question__text"},[_vm._v(" Хочу что-то новое и крутое ")]),_c('img',{staticClass:"question__pic",attrs:{"src":_vm._f("prodSrcImage")('/img/pages/onboarding/pic__question-20--x2.png'),"alt":"Иконка"}})])]),(_vm.$v.form.currentCard.$error)?_c('div',{staticClass:"questionary__list"},[_c('div',{staticClass:"error-field"},[_vm._v(" Выберите что тебе нравится ")])]):_vm._e()]),_c('div',{staticClass:"panel-onboarding__footer"},[_c('button',{staticClass:"questionary__back"}),(!_vm.isSending)?_c('button',{staticClass:"button orange",on:{"click":_vm.next}},[_vm._v(" Вперед "),_c('img',{staticClass:"button__icon-right",attrs:{"src":_vm._f("prodSrcImage")('/img/icons/icon__arrow-right--white.svg'),"alt":"Иконка"}})]):_c('div',{staticClass:"button orange button-preloader",staticStyle:{"width":"132px"}},[_vm._m(2)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('div',{staticClass:"steps-number"},[_c('div',{staticClass:"steps-number__current"},[_vm._v(" 8 ")]),_c('div',{staticClass:"steps-number__total"},[_vm._v(" /9 ")])]),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step step--active"}),_c('div',{staticClass:"step"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-onboarding__header"},[_c('div',{staticClass:"block-title"},[_vm._v(" Выбери направление обучения ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-preloader-icons"},[_c('div',{staticClass:"button-preloader-icon"}),_c('div',{staticClass:"button-preloader-icon"}),_c('div',{staticClass:"button-preloader-icon"})])
}]

export { render, staticRenderFns }