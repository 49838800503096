<template>
	<div class="sections">
		<section class="questionary">
			<div class="content">
				<div class="panel-onboarding">
					<div class="steps">
						<div class="steps-number">
							<div class="steps-number__current">
								9
							</div>
							<div class="steps-number__total">
								/9
							</div>
						</div>
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
						<div class="step step--active" />
					</div>
					<div class="offer">
						<div class="offer__title">
							В личном кабинете наблюдай за своими успехами и прогрессом в «Лифте в будущее»
						</div>
						<img
							:src="'/img/pages/onboarding/pic__offer.png'	| prodSrcImage"
							alt="Картинка"
							class="offer__pic"
						>
					</div>
					<div class="panel-onboarding__footer">
						<button class="questionary__back" />
						<button
							class="button orange"
							@click="next"
						>
							Вперед
							<img
								:src="'/img/icons/icon__arrow-right--white.svg'	| prodSrcImage"
								alt="Иконка"
								class="button__icon-right"
							>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>


<script>
export default {
	methods: {
		next() {
			this.$emit('next', 'StudentStepsCompleted');
		}
	}
}
</script>

<style>

</style>
